<template>
  <div>
    <el-card class="box-card">
      <div class="wrap">
        <div class="title">当前信息</div>
        <div class="content">
          <el-form label-width="150px">
            <el-form-item label="当前版本：">
              <div>企业版</div>
            </el-form-item>
            <el-form-item label="当前版本有效期：">
              <!-- <div>{{ seatDetail.validTime }}</div> -->
              <div>{{ time(buyForm.year) }}</div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="wrap">
        <div class="title">购买内容</div>
        <div class="content">
          <el-form :ref="buyForm" label-width="150px" :model="buyForm">
            <el-form-item label="人数：" class="people">
              <div>
                <el-input
                  v-model="buyForm.people"
                  class="input-width"
                  maxlength="4"
                  clearable
                ></el-input>
                人（￥4.00元/月/人）
              </div>
            </el-form-item>
            <el-form-item label="使用年数：">
              <div>
                <el-input-number
                  v-model="buyForm.year"
                  :min="1"
                  :max="99"
                  @change="handleChange"
                ></el-input-number>
                年
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="wrap">
        <div class="title">订单详情</div>
        <div class="content">
          <el-form label-width="150px">
            <el-form-item label="订单类型：">
              <div>员工席位</div>
            </el-form-item>
            <el-form-item label="生效时间：">
              <div>{{ time(0) }} - {{ time(buyForm.year) }}</div>
            </el-form-item>
            <el-form-item label="平台使用费：">
              <div>￥12999.00元/年</div>
            </el-form-item>
            <el-form-item label="使用人数：">
              <div v-if="buyForm.people">{{ buyForm.people }}人</div>
              <div v-else>0人</div>
            </el-form-item>
            <el-form-item label="优惠金额：">
              <div>新签免除平台使用费 ￥12999.00元</div>
            </el-form-item>
            <el-form-item label="订单金额：">
              <div v-if="buyForm.people" style="color:#FF583E">{{ price }}元</div>
              <div v-else style="color:#FF583E">￥0元</div>
            </el-form-item>
            <el-form-item label="订单明细：" class="orderDetail">
              <el-table
                :data="tableData"
                border
                :summary-method="getSummaries"
                show-summary
                style="width:408px"
              >
                <el-table-column prop="id" label="类目"> </el-table-column>
                <el-table-column prop="name" label="单价"> </el-table-column>
                <el-table-column prop="amount1" label="数量"> </el-table-column>
                <el-table-column prop="amount2" label="总价"> </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="支付方式：">
              <div class="payType">
                <div class="balancePay">
                  <img src="../../../assets/college/Accounts/wrap.png" alt="" />
                  <span class="balance">余额支付</span>
                </div>
                <div class="nowMoney">
                  当前余额<span class="moneyNum">￥{{ accountsDetail.balanceAmount }}</span
                  >元
                </div>
                <div class="recharge" @click="recharge">充值</div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="agreement">
        <!-- <div class="agreement_checked">
          <div><el-checkbox v-model="checked"></el-checkbox></div>
          <div class="agreement_text">勾选即同意《协议***********》</div>
        </div> -->
        <div class="btn">
          <el-button type="primary" size="medium" :loading="submitLoading" @click="surePay"
            >确定支付</el-button
          >
        </div>
      </div>
    </el-card>

    <!-- <Recharge ref="Recharge"></Recharge> -->
  </div>
</template>

<script>
// import Recharge from './commonents/Recharge'
import to from 'await-to'
import { getAccountsMoney, getAccountsSeatOrder, getAccountsSeatPay } from '@/api/college'
export default {
  components: {
    // Recharge,
  },
  data() {
    return {
      buyForm: {
        people: '',
        year: 1,
      },
      checked: false,
      submitLoading: false,
      tableData: [
        {
          id: '平台使用费',
          name: '单价',
          amount1: '1',
          amount2: '0.00',
        },
        {
          id: '席位费',
          name: '48.00',
          amount1: '',
          amount2: '',
        },
      ],
      accountsDetail: {},
      price: 0,
    }
  },
  watch: {
    'buyForm.people'(newVal) {
      this.tableData.map((v, i) => {
        if (i == this.tableData.length - 1) {
          if (newVal !== '') {
            v.amount1 = newVal
            v.amount2 = (v.amount1 * v.name * this.buyForm.year).toFixed(2)
            this.price = '￥' + v.amount2
          } else {
            v.amount1 = ''
            v.amount2 = ''
            this.price = '￥' + 0
          }
        }
      })
    },
    'buyForm.year'(newVal) {
      this.tableData.map((v, i) => {
        if (i == this.tableData.length - 1) {
          if (newVal !== '') {
            v.amount2 = (v.amount1 * v.name * newVal).toFixed(2)
            this.price = '￥' + v.amount2
          }
        }
      })
    },
  },
  created() {
    this.getAccountsMoneyData()
  },
  methods: {
    time(year) {
      var d1 = new Date()
      var d2 = new Date(d1)
      d2.setFullYear(d2.getFullYear() + year)
      d2.setDate(d2.getDate() - 1)
      // alert(d2.toLocaleString())
      let Y = d2.getFullYear(),
        m = d2.getMonth() + 1,
        d = d2.getDate(),
        H = d2.getHours(),
        i = d2.getMinutes(),
        s = d2.getSeconds()
      if (m < 10) {
        m = '0' + m
      }
      if (d < 10) {
        d = '0' + d
      }
      if (H < 10) {
        H = '0' + H
      }
      if (i < 10) {
        i = '0' + i
      }
      if (s < 10) {
        s = '0' + s
      }
      var t = Y + '-' + m + '-' + d
      return t
    },
    async getAccountsMoneyData() {
      const [res, err] = await to(getAccountsMoney())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.accountsDetail = res.data
    },
    handleChange(val) {
      this.buyForm.year = val
      console.log(val)
      if (this.buyForm.year.length > 4) {
        this.buyForm.year = this.buyForm.year.substr(0, 4)
      }
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      // console.log(columns, data)
      var price = 0
      data.map(v => {
        price += Number(v.amount2)
      })
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '小计'
          return
        }
        if (index === columns.length - 1) {
          sums[index] = '￥' + price.toFixed(2) + '元'
          return
        }
      })

      return sums
    },
    recharge() {
      // this.$refs.Recharge.show()
      this.$router.push({
        name: 'AccountsManage',
        params: {
          showRecharge: 1,
        },
      })
    },
    async surePay() {
      if (this.buyForm.people == '') {
        this.$message({ type: 'warning', message: '请输入人数' })
      } else if (this.buyForm.people > 9999) {
        this.$message({ type: 'warning', message: '人数最多输入9999人' })
      } else {
        this.submitLoading = true
        const [res, err] = await to(
          getAccountsSeatOrder({ stuAdd: this.buyForm.people, timeAdd: this.buyForm.year }),
        )
        if (err) {
          this.submitLoading = false
          return this.$message({ type: 'error', message: err.msg })
        }
        this.getAccountsSeatPayData(res.data)
      }
    },
    async getAccountsSeatPayData(id) {
      const [, err] = await to(getAccountsSeatPay({ orderNo: id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.submitLoading = false
      this.$message({ type: 'success', message: '续费成功' })
      this.$router.push('accountsManage')
    },
  },
}
</script>

<style scoped lang="scss">
.wrap {
  .input-width {
    width: 120px;
    height: 34px;
  }
  .title {
    line-height: 42px;
    font-size: 16px;
    color: #606266;
    background-color: #f6f6f6;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .content {
    font-size: 14px;
    color: #333;
    .orderDetail {
      ::v-deep .el-form-item__content {
        line-height: 0 !important;
      }
    }
    .people {
      margin-top: 22px;
    }
    .payType {
      background-color: #fff5f0;
      display: flex;
      align-items: center;
      padding-left: 20px;
      img {
        width: 20px;
        height: 17px;
        margin: auto;
      }
      .balancePay {
        display: flex;
        align-items: center;
        .balance {
          margin-left: 6px;
          color: #333;
        }
      }
      .nowMoney {
        color: #909399;
        margin: 0 39px 0 12px;
        .moneyNum {
          color: #ff7b33;
        }
      }
      .recharge {
        line-height: 24px;
        text-align: center;
        color: #ff7b33;
        border: 1px solid #ff7b33;
        cursor: pointer;
        padding: 0 12px;
      }
    }
  }
}
.agreement {
  margin-top: 38px;
  .agreement_checked {
    display: flex;
    line-height: 20px;
    margin-left: 150px;
    .agreement_text {
      color: #909399;
      font-size: 14px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .btn {
    margin-left: 150px;
    margin-top: 17px;
  }
}
.accounts {
  ::v-deep .el-dialog__footer {
    text-align: center;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .radio_wrap {
    display: flex;
    height: 40px;
    line-height: 40px;
    .radio_img {
      height: 20px;
      width: 20px;
      .weixin {
        width: 25px;
        height: 25px;
      }
    }
  }
}
</style>
